import "./App.css";
import {
  WeeklyCongestionChart,
  WeeklyCongestionLegend,
} from "./WeeklyCongestionChart";
import { ReactComponent as Logo } from "./logo.svg";
import { useState } from "react";
import useMeasure from "react-use-measure";

export function App() {
  const [container, rect] = useMeasure();
  const [params] = useState(() => {
    const search = new URLSearchParams(window.location.search);

    return {
      country: search.get("country"),
    };
  });

  return (
    <div className="App" ref={container}>
      <WeeklyCongestionChart country={params.country} labelType="city" />
      <div className="App__footer">
        <WeeklyCongestionLegend boxesCount={rect.width < 450 ? 5 : 8} />
        <a
          href="https://www.tomtom.com/en_gb/traffic-index/"
          className="SourceLink"
          target="_parent"
        >
          <div className="Logo__label">Source: TomTom</div>
        </a>
      </div>
    </div>
  );
}
