import { useRef, useEffect } from 'react';

export const useDocumentEvent = (type, listener) => {
  const listenerRef = useRef();

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    const exec = e => {
      if (listenerRef.current) {
        listenerRef.current(e);
      }
    };
    document.addEventListener(type, exec);

    return () => {
      document.removeEventListener(type, exec);
    };
  }, []);
};
