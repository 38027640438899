import EVENT_TYPES from "./eventTypes";

const eventColors = {
  [EVENT_TYPES.COVID_PARTIAL]: {
    fill: "#fff",
    stroke: "#d0d0d0",
  },
  [EVENT_TYPES.COVID_EASE]: {
    fill: "#fff",
    stroke: "#ddd",
  },
  [EVENT_TYPES.COVID_LOCKDOWN]: {
    fill: "#d0d0d0",
    stroke: "#d0d0d0",
  },
};

const divergingPalette = {
  less: ["#187abc", "#4593cd", "#6babdd", "#92c4ea", "#bcdcf5"],
  more: ["#ffad9e", "#f47a70", "#e94743", "#cd2c29", "#b1110e"],
};

const divergingHotColdPalette = {
  less: ["#4593cd"],
  more: ["#cd2c29"],
};

const constantPalette = ["#cd2c29"];

const sequentialPaletteGreenToRed = [
  "#3d814f",
  "#559a66",
  "#6eb27d",
  "#8cc78c",
  "#bccf84",
  "#ebd87c",
  "#fed367",
  "#fcc347",
  "#f9b228",
  "#fba118",
  "#fd8f0b",
  "#fd7b01",
  "#f25508",
  "#e6300e",
  "#dc1710",
  "#d60d0b",
  "#d00305",
  "#bf0003",
  "#a80001",
  "#920000",
];

const congestionPaletteSettings = {
  SEQUENTIAL: { settings: sequentialPaletteGreenToRed },
  DIVERGING: { settings: divergingPalette, isDiverging: true },
  DIVERGING_HOT_COLD: { settings: divergingHotColdPalette, isDiverging: true },
  CONSTANT: { settings: constantPalette, isConstant: true },
  NO_DATA: "#d8d6d6",
};

export { eventColors, congestionPaletteSettings };
