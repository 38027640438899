import { useMemo, useState } from "react";
import citiesWeekly from "./weeklyData2020vs2019.json";

const countries = (() => {
  const allCountries = new Set();
  citiesWeekly.forEach((city) => {
    allCountries.add(city.countryName);
  });
  return [...allCountries].sort((a, b) => a.localeCompare(b));
})();

export function URLCreator() {
  const [country, setCountry] = useState("Netherlands");

  const iframe = useMemo(() => {
    const cities = citiesWeekly.filter((city) => city.countryName === country)
      .length;

    let unitHeight = 20;
    if (cities < 3) unitHeight = 40;
    if (cities > 20) unitHeight = 16;

    const height = unitHeight * cities + 95;

    return `<iframe width="100%" height="${height}" src="${
      window.location.origin
    }/?country=${encodeURIComponent(country)}" style="border: none;"></iframe>`;
  }, [country]);

  return (
    <div style={{ fontFamily: "Noway" }}>
      <div style={{ padding: "10px" }}>
        <label>Country</label>
        <select
          onChange={(e) => setCountry(e.target.value)}
          value={country}
          style={{ marginLeft: "10px" }}
        >
          {countries.map((it) => (
            <option value={it} key={it}>
              {it}
            </option>
          ))}
        </select>
      </div>

      <div style={{ padding: "10px" }}>
        <label>Code:</label>
        <br />
        <textarea cols="50" rows="10" value={iframe} />
      </div>
    </div>
  );
}
