import { congestionPaletteSettings } from "./colors";

export const getPercentileValue = (sortedValues, percentile) => {
  const index = Math.floor((percentile / 100) * sortedValues.length);
  return sortedValues[index];
};

export const getPercentileValues = (data, percentiles, isSorted) => {
  const dataSorted = !isSorted ? data.sort((c1, c2) => c1 - c2) : data;

  const percentileValues = percentiles.map((percentile) =>
    getPercentileValue(dataSorted, percentile)
  );
  return percentileValues;
};

const getConstantPaletteColor = (value, palette, noDataColor) => {
  if (value === undefined) {
    return noDataColor;
  }

  return palette[0].color;
};
const getPaletteColor = (value, palette, noDataColor) => {
  if (value === undefined) {
    return noDataColor;
  }

  const paletteIndex = palette.reduce((chosen, { threshold }, index) => {
    return value > threshold ? index : chosen;
  }, 0);

  return palette[paletteIndex].color;
};

const generateSequentialPalette = (min, max, paletteSettings) => {
  const step = (max - min) / paletteSettings.length;

  const dataPalette = paletteSettings.map((color, index) => {
    const threshold = min + index * step;
    return {
      threshold,
      color,
    };
  });
  return dataPalette;
};

const generateDivergingPalette = (min, max, paletteSettings) => {
  const lessPart = generateSequentialPalette(-max, min, paletteSettings.less);
  const morePart = generateSequentialPalette(min, max, paletteSettings.more);
  return [...lessPart, ...morePart];
};

const generatePalette = (
  min,
  max,
  paletteKey,
  { noDataColor = congestionPaletteSettings.NO_DATA } = {}
) => {
  const paletteSettings = congestionPaletteSettings[paletteKey];

  const { isDiverging, isConstant } = paletteSettings;

  const palette = isDiverging
    ? generateDivergingPalette(min, max, paletteSettings.settings)
    : generateSequentialPalette(min, max, paletteSettings.settings);

  const colorFunction = isConstant
    ? (value) => getConstantPaletteColor(value, palette, noDataColor)
    : (value) => getPaletteColor(value, palette, noDataColor);

  return {
    palette,
    getColor: colorFunction,
  };
};

export { generatePalette };
