import "./WeeklyCongestionChart.css";
import React, { useMemo, useRef, useEffect, useState } from "react";
import cx from "classnames";

import { generatePalette } from "./colorPalettes";
import citiesWeekly from "./weeklyData2020vs2019.json";
import { useDocumentEvent } from "./useDocumentEvent";
import { useSpring, animated } from "react-spring";
import { allMonths, allMonthsShort } from "./months";
import { interestingCitiesKeys } from "./interestingCitiesKeys";
import { useElementWidth } from "./useElementWidth";
// import { BigSwitch } from "./BigSwitch";
import useMeasure from "react-use-measure";

const weeksIn2020 = 52;
const weeklyData = citiesWeekly;

const paletteSequential = generatePalette(2, 60, "SEQUENTIAL");
const paletteDiverging = generatePalette(0, 1, "DIVERGING");

const narrowThreshold = 500;

export function WeeklyCongestionLegend({
  diff,
  mobileOnly,
  desktopOnly,
  boxesCount = 8,
}) {
  const palette = diff ? paletteDiverging : paletteSequential;

  const values = diff
    ? [...Array(boxesCount)].map((_, i) => (2 / boxesCount) * i - 1)
    : [...Array(boxesCount)].map((_, i) => 2 + ((60 - 2) / boxesCount) * i);

  const [labelLeft, labelRight] = diff
    ? ["Less congestion", "More congestion"]
    : ["No congestion", "Heavy congestion"];

  const className = cx("WeeklyCongestionLegend", {
    "WeeklyCongestionLegend--mobile": mobileOnly,
    "WeeklyCongestionLegend--desktop": desktopOnly,
  });

  return (
    <div className={className}>
      <div className="WeeklyCongestionLegend__label-left">{labelLeft}</div>
      {values.map((it) => (
        <div
          key={it}
          className="WeeklyCongestionLegend__box"
          style={{
            background: palette.getColor(it),
          }}
        />
      ))}
      <div className="WeeklyCongestionLegend__label-right">{labelRight}</div>
    </div>
  );
}

function WeeklyCongestionHighlight() {
  return (
    <div className="WeeklyCongestionHighlight">
      <div className="WeeklyCongestionHighlight__dotted-line" />
      <div className="WeeklyCongestionHighlight__week">Week 15</div>
      <div className="WeeklyCongestionHighlight__label">
        Second week of April
      </div>
      <p className="WeeklyCongestionHighlight__description">
        Week during which there were observed the biggest drop in congestion
        levels in comparison to 2020.
      </p>
      <div className="WeeklyCongestionHighlight__amount">
        132 cities | 25 countires
      </div>
    </div>
  );
}

export function ComparisonView({ unitHeight, data }) {
  const container = useRef();

  const height = data.length * unitHeight;
  const width = useElementWidth(container);
  const [down, setDown] = useState(false);
  const [hover, setHover] = useState(false);
  const [props, set] = useSpring(() => ({
    left: 0,
    immediate: true,
    config: {
      tension: 50,
      friction: 26,
    },
  }));

  const moveHandle = (left, immediate = true) => set({ left, immediate });

  const mouseLeft = (e) => {
    const rect = container.current.getBoundingClientRect();
    const left = e.clientX - rect.left;
    return left;
  };

  // Position handle on start
  useEffect(() => {
    const rect = container.current.getBoundingClientRect();
    const from = rect.width / 1.5;
    moveHandle(from);
  }, []);

  // Animate when in view
  const initialAnimationStarted = useRef(false);
  useEffect(() => {
    if (!container.current) return;
    const rect = container.current.getBoundingClientRect();
    const to = rect.width / 6;

    // Start animation on intersection
    const observer = new IntersectionObserver(
      (e) => {
        if (!initialAnimationStarted.current && e[0].isIntersecting) {
          initialAnimationStarted.current = true;
          moveHandle(to, false);
        }
      },
      { threshold: 0.3 }
    );
    observer.observe(container.current);
  }, []);

  useDocumentEvent("pointermove", (e) => {
    if (!container.current) {
      return;
    }

    if (down) {
      const rect = container.current.getBoundingClientRect();
      const left = e.clientX - rect.left;
      e.preventDefault();
      moveHandle(Math.max(0, Math.min(left, rect.width)));
    }
  });

  const hoverThreshold = 40;
  const onMove = (e) => {
    const left = mouseLeft(e);
    const hovering = Math.abs(left - props.left.value) < hoverThreshold;
    setHover(hovering);
  };

  const onDown = (e) => {
    const left = mouseLeft(e);
    if (Math.abs(left - props.left.value) < hoverThreshold) {
      setDown(true);
      moveHandle(left);
    }
  };

  useDocumentEvent("pointerup", (e) => {
    setDown(false);
    setHover(false);
  });

  return (
    <div
      className="WeeklyCongestionChart__container"
      style={{
        height,
        touchAction: "none",
        cursor: down || hover ? "ew-resize" : "",
      }}
      onPointerDown={onDown}
      onPointerMove={onMove}
      ref={container}
    >
      <animated.div
        className="WeeklyCongestionChart__handle"
        style={{ left: props.left }}
      >
        <div
          className="WeeklyCongestionChart__handle-line"
          style={{ width: hover || down ? "8px" : "4px" }}
        />
        <div className="WeeklyCongestionChart__handle-triangle-left" />
        <div className="WeeklyCongestionChart__handle-triangle-right" />
        <div
          className="WeeklyCongestionChart__handle-label"
          style={{ transform: "translateX(-100%)", left: "-10px" }}
        >
          2019
        </div>
        <div
          className="WeeklyCongestionChart__handle-label"
          style={{ left: "15px", top: "14px" }}
        >
          2020
        </div>
      </animated.div>
      <animated.div
        className="WeeklyCongestionChart__container-left"
        style={{ width: props.left }}
      >
        <WeeklyBoxes
          data={data}
          metric="2019"
          unitHeight={unitHeight}
          width={width}
        />
      </animated.div>
      <div className="WeeklyCongestionChart__container-right">
        <WeeklyBoxes
          data={data}
          metric="2020"
          unitHeight={unitHeight}
          width={width}
        />
      </div>
    </div>
  );
}

const displayTypes = ["compare", "difference"];
export function WeeklyCongestionOverview() {
  const [selectedCountry, setSelectedCountry] = useState("Selected");
  const [displayType, setDisplayType] = useState(displayTypes[0]);

  const countries = useMemo(() => {
    const allCountries = new Set();
    weeklyData.forEach((city) => {
      allCountries.add(city.countryName);
    });
    return [...allCountries];
  }, [weeklyData]);

  const props = useMemo(() => {
    if (selectedCountry === "Selected") {
      return {
        cities: interestingCitiesKeys,
        labelType: "city",
      };
    }
    if (selectedCountry === "All") {
      return {};
    }
    return { country: selectedCountry, labelType: "city" };
  }, [selectedCountry]);

  return (
    <div className="grid-page WeeklyCongestionOverview">
      <h2>Tracking the impact of COVID-19 through traffic</h2>
      <div className="WeeklyCongestionOverview__header">
        {/* <BigSwitch
          values={displayTypes}
          value={displayType}
          onChange={setDisplayType}
        /> */}
        <p className="WeeklyCongestionOverview__description">
          Compare weekly congestion levels in corresponding weeks of 2019 and
          2020. The congestion levels are weighted averages derived from hourly
          data. Each week starts on Monday and ends on Sunday.
        </p>
        {/* <SimpleSelect
          options={[
            { value: "Selected", label: "Selected" },
            { value: "All", label: "All" },
            ...countries
              .sort((a, b) => a.localeCompare(b))
              .map((it) => ({ value: it, label: it })),
          ]}
          value={selectedCountry}
          onChange={setSelectedCountry}
          selectProps={{
            isSearchable: true,
          }}
        /> */}
      </div>
      <WeeklyCongestionLegend
        diff={displayType === displayTypes[1]}
        mobileOnly
      />
      <WeeklyCongestionChart
        {...props}
        diff={displayType === displayTypes[1]}
        compact
      />
      <WeeklyCongestionLegend
        diff={displayType === displayTypes[1]}
        desktopOnly
      />
      <WeeklyCongestionHighlight />
    </div>
  );
}

export function WeeklyCongestionChart({
  // 'country' | 'city'
  labelType = "country",
  country = undefined,
  cities = undefined,
  diff = false,
  compact = false,
}) {
  const data = useMemo(() => {
    if (cities) {
      return cities.map((city) => weeklyData.find((it) => it.name === city));
    }
    if (country) {
      return weeklyData.filter((it) => it.countryName === country);
    }
    return weeklyData.sort((a, b) =>
      a.countryName.localeCompare(b.countryName)
    );
  }, [country, cities]);

  let unitHeight = 20;
  if (data.length < 3) unitHeight = 40;
  if (data.length > 20) unitHeight = 16;

  const names = useMemo(() => {
    let nameElements = [];
    let country;
    data.forEach((city, i) => {
      if (labelType === "city") {
        nameElements.push(
          <div
            key={city.key}
            style={{
              top: unitHeight * (i + 0.5),
              marginTop: "-5px",
            }}
            className="WeeklyCongestionChart__city-name"
            alt={city.name}
          >
            {city.name}
          </div>
        );
      }

      if (labelType === "country" && country !== city.countryName) {
        country = city.countryName;
        nameElements.push(
          <div
            key={city.key}
            style={{
              top: unitHeight * i,
              marginTop: "-3px",
            }}
            className="WeeklyCongestionChart__country-name"
          >
            {city.countryName}
          </div>
        );
      }
    });
    return nameElements;
  }, [data]);

  const [container, rect] = useMeasure();
  const width = rect.width;

  const [chartContainer, chartRect] = useMeasure();
  const isCompact = chartRect.width < 700;
  const months = useMemo(() => {
    return (isCompact ? allMonthsShort : allMonths).map((it, i) => (
      <div className="WeeklyCongestionChart__month-label" key={i}>
        {it}
      </div>
    ));
  }, [isCompact]);

  return (
    <div className="WeeklyCongestionChart" ref={chartContainer}>
      <div className="WeeklyCongestionChart__labels">{names}</div>

      <div style={{ flexGrow: 1, position: "relative", overflow: "hidden" }}>
        <div
          className="WeeklyCongestionChart__horizontal-labels"
          style={{ height: "30px" }}
        >
          {months}
        </div>
        {diff ? (
          <div ref={container} className="WeeklyCongestionChart__container">
            <WeeklyBoxes
              data={data}
              metric="diff"
              unitHeight={unitHeight}
              width={width}
              diff
            />
          </div>
        ) : (
          <ComparisonView unitHeight={unitHeight} data={data} />
        )}
      </div>
    </div>
  );
}

const useThrottledEffect = (fn, deps) => {
  let requestId = undefined;

  let later = () => {
    requestId = undefined;
    fn();
  };

  useEffect(() => {
    if (requestId === undefined) {
      requestId = requestAnimationFrame(later);
    } else {
      cancelAnimationFrame(requestId);
      requestId = requestAnimationFrame(later);
    }
  }, deps);
};

const getWeeklyCl = (city, week, year) => {
  if (!city.weeks[week]) return;
  return city.weeks[week][year];
};

const lockdownExcpetions = {
  "grand-rapids": 11,
  minneapolis: 11,
};

export function WeeklyBoxes({ data, metric, unitHeight, width, diff = false }) {
  const canvas = useRef();
  const palette = diff ? paletteDiverging : paletteSequential;

  const totalHeight = data.length * unitHeight;

  useThrottledEffect(() => {
    if (!canvas.current) {
      return;
    }
    const totalWidth = width;
    canvas.current.width = totalWidth;

    const unitWidth =
      //   width < narrowThreshold
      totalWidth / weeksIn2020;
    // : Math.round(totalWidth / weeksIn2020);
    const lineWidth = 4;
    const padding = 1;

    const ctx = canvas.current.getContext("2d");
    const pixelRatio = window.devicePixelRatio || 1;
    canvas.current.width = totalWidth * pixelRatio;
    canvas.current.height = totalHeight * pixelRatio;
    ctx.scale(pixelRatio, pixelRatio);
    canvas.current.style.width = `${totalWidth}px`;
    canvas.current.style.height = `${totalHeight}px`;

    ctx.clearRect(0, 0, totalWidth, totalHeight);
    // ctx.imageSmoothingEnabled = false;

    let prevLockdownX = undefined;

    data.forEach((city, index) => {
      const y = index * unitHeight;

      let lockdownDone = metric !== "2020";
      let prevWeek = 0;

      let currentLockdownX = undefined;

      let weekCount = 1;
      city.weeks.forEach((week, weekIndex) => {
        const value = week[metric];
        const weekNumber = week.week;

        const x = (weekNumber - 1) * unitWidth;
        // Decrease whitespace on narrow screens
        const p = width < narrowThreshold ? padding / 2 : padding;
        ctx.fillStyle = palette.getColor(value);
        ctx.fillRect(x + p, y + p, unitWidth - p, unitHeight - p);

        // skipped some data
        if (weekNumber !== weekCount) {
          const skippedX = (weekCount - 1) * unitWidth;
          ctx.fillStyle = "#ddd";
          ctx.fillRect(
            skippedX + p,
            y + p,
            unitWidth * (weekNumber - weekCount) - p,
            unitHeight - p
          );
          weekCount = weekNumber;
        }
        weekCount++;

        // Determine if lockdown happened this week
        // by looking at how cl changed compared to previous year
        // and previous year
        if (!lockdownDone) {
          // Skip some exceptions
          if (
            lockdownExcpetions[city.key] !== undefined &&
            lockdownExcpetions[city.key] !== weekIndex
          ) {
            return;
          }
          const cl2019 = city.weeks[weekIndex]["2019"];

          const clPrevPrevPrev =
            getWeeklyCl(city, weekIndex - 3, "2020") || value;
          const clPrevPrev = getWeeklyCl(city, weekIndex - 2, "2020") || value;
          const clPrev = getWeeklyCl(city, weekIndex - 1, "2020") || value;
          const clNext = getWeeklyCl(city, weekIndex + 1, "2020") || value;
          const clNextNext = getWeeklyCl(city, weekIndex + 2, "2020") || value;

          const clMin = Math.min(clPrev, clPrevPrev, clPrevPrevPrev);

          // Maximum cl from 3 days
          const clMax = Math.max(value, clNext, clNextNext);

          // Change from previous year
          const diffYearly = (cl2019 - value) / cl2019;

          // Change from previous week
          const diffWeekly = (clMin - clMax) / clMin;

          if (
            weekIndex < 20 &&
            weekIndex > 2 && // ignore some strange drops at the start of the year
            diffWeekly + diffYearly > 0.61 &&
            value < 30
          ) {
            ctx.fillStyle = "#FFF";
            ctx.fillRect(x - lineWidth / 2, y + p, lineWidth, unitHeight - p);
            currentLockdownX = x;
            lockdownDone = true;
          }
        }
        prevWeek = value;
      });

      // Draw horizontal line to join to the previous lockdown vertical line
      if (lockdownDone && currentLockdownX && prevLockdownX) {
        ctx.fillStyle = "#FFF";
        ctx.fillRect(
          Math.min(prevLockdownX, currentLockdownX) - lineWidth / 2,
          y - lineWidth / 2,
          Math.abs(prevLockdownX - currentLockdownX) + lineWidth,
          lineWidth
        );
      }
      prevLockdownX = currentLockdownX;
    });
  }, [data, width]);

  return (
    <canvas
      ref={canvas}
      height={totalHeight}
      style={{ background: "#FFF", imageRendering: "pixelated" }}
    />
  );
}
