import { App } from "./App";
import { URLCreator } from "./URLCreator";

export function Main() {
  const route = window.location.href.includes("configure")
    ? "configure"
    : "chart";

  return route === "configure" ? <URLCreator /> : <App />;
}
